@import "variables.scss";
@import "mixins.scss";
.ant-collapse-borderless > .ant-collapse-item:last-child {
    border-bottom: none;
}
.ant-page-header-title-view-title {
    padding-right: 20px;
    border-right: 1px solid #ccc;
    margin-right: 20px;
}
.ant-page-header {
    padding-bottom: 9px;
    padding-top: 20px;
}

.ant-statistic-content {
    font-size: 2rem;
}
.ant-spin-blur {
    opacity: 0.8;
}
.ant-tree li {
    overflow: hidden;
}
.ant-table-tbody > tr > td {
    padding: 5px 16px;
}
.ant-layout-sider-collapsed {
    .ant-menu-item {
        font-size: 0;
    }
    .socialNavSubMenu {
        margin-left: 0 !important;
        font-size: 0;
    }
}
.ant-modal.socialCustomReport {
    background-color: $color-customReport;
}
.ant-form-item {
    margin-bottom: 0;
}
.ant-menu-inline > .ant-menu-item {
    height: 30px;
    line-height: 30px;
}
.social_TabsOnlyIcons {
    .ant-tabs-nav .ant-tabs-tab {
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
}
.ant-card[disabled] {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}
.ant-list-item-meta-description {
    padding-left: 25px;
}
form .ant-select,
form .ant-cascader-picker {
    width: auto;
}
.ant-form-item-label {
    line-height: 1.1;
    margin-top: 10px;
}
.ant-form-item-control {
    display: flex;
    flex-direction: column-reverse;
    .ant-form-extra {
        font-size: 15px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}
form textarea.ant-input {
    margin-top: 4px;
}
.ant-tabs-content {
    //padding: 20px;
}
.ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 34px;
}
.ant-checkbox + span {
    padding-right: 0;
}
.ant-modal-body {
    // padding-top: 0;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    height: 400px;
}
.ant-fullcalendar-tbody {
    // border: 1px solid red;
    height: calc(100vh - 300px);
    display: block;
    overflow: auto;
    width: 100%;
    tr {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
    }
}
.ant-fullcalendar table thead tr {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
}
.ant-fullcalendar-column-header {
    width: unset;
}

/* Header */
.ant-page-header-heading {
    .ant-avatar {
        margin-right: -5px;
    }
    .ant-btn-primary {
        margin-right: 15px !important;
        width: 138px;
    }
    .ant-badge-count {
        // right: 13px;
        font-size: 12px;
        background-color: $color-palette-category;
    }
}
.social_topRight_menu {
    width: 164px;
}

.social_ProfileMenu {
    .ant-menu-item {
        padding: 0;
        margin: unset;
        line-height: 30px;
        margin-bottom: 0;
        height: unset;
    }
}

/* List */
.ant-list-header .ant-col {
    // font-weight: bold;
    text-transform: uppercase;
    // color: $color_Primary;
    width: 100% !important;
}
.ant-list-bordered.ant-list-sm .ant-list-item:hover {
    // background-color: rgba($color_Grey, 0.1);
}

/* List - Global */
.ant-list {
    // border: 1px solid red;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        // border: 1px solid green;
        display: grid !important;

        grid-template-rows: auto;
        &::before {
            display: none;
        }
        .ant-col {
            width: unset !important;
            padding: 0 !important;
            text-align: left !important;
        }
    }

    /* Rows */
    ul.ant-list-items {
        padding: 0 0px;
        & > li.ant-list-item {
            text-align: left;
            padding-left: 13px;
            padding-right: 13px;
            width: 100%;
            grid-template-rows: auto;
            column-gap: 0;
            justify-items: stretch;
            display: grid;
            align-items: center;
            justify-content: stretch;

            & > * {
                // border: 1px solid red;
                width: unset !important;
                flex: unset !important;
                display: unset !important;
                text-align: left !important;
                text-overflow: ellipsis;
                overflow: hidden;

                &.ant-list-item-action {
                    margin-left: 0;
                    & > li:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

$grid_lastColumnWidth: 200px;
$grid_checkboxColumnWidth: 25px;
/* List - AccountsList */
// $grid_AccountsList_Header: $grid_checkboxColumnWidth
//     calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
//     calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
//     calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
//     $grid_lastColumnWidth;
$grid_AccountsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.33, 0.33, 0.34)
);
$grid_AccountsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.33, 0.33, 0.34)
);
// $grid_AccountsList_Row: calc(
//         ((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3) +
//             30px
//     )
//     calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
//     200px;

.ant-list.AccountsList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_AccountsList_Header;
    }
    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_AccountsList_Row;
    }
}
/* List - AccountsChannelsList */
// $grid_AccountsChannelsList_Header: 40% auto $grid_lastColumnWidth;
// $grid_AccountsChannelsList_Row: 40% auto 174px;

$grid_AccountsChannelsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_AccountsChannelsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);

.ant-list.AccountsChannelsList {
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_AccountsChannelsList_Header;
    }
    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_AccountsChannelsList_Row;
    }
}
/* List - TagsList */
// $grid_TagsList_Header: 25px calc(50% - 23px) auto $grid_lastColumnWidth;
// $grid_TagsList_Row: calc(50% + 7px) auto 52px;
$grid_TagsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_TagsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);

.ant-list.TagsList {
    // border: 1px solid red;
    @extend .social_MainListScrolling;

    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_TagsList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_TagsList_Row;
    }
}
/* List - MasterpostsList */
// $grid_MasterpostsList_Header: 25px 1fr 1fr 1fr $grid_lastColumnWidth;
// $grid_MasterpostsList_Row: calc(33% + 7px) calc(33% - 15px) 100px 192px;
$grid_MasterpostsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.33, 0.33, 0.34)
);
$grid_MasterpostsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.33, 0.33, 0.34)
);
.ant-list.MasterpostsList {
    @extend .social_MainListScrolling;

    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_MasterpostsList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_MasterpostsList_Row;
    }
}
/* List - CategoriesList */
// $grid_CategoriesList_Header: 25px calc(50% - 23px) auto $grid_lastColumnWidth;
// $grid_CategoriesList_Row: calc(50% + 7px) auto 87px;
$grid_CategoriesList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_CategoriesList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
.ant-list.CategoriesList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_CategoriesList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_CategoriesList_Row;
    }
}
/* List - CampaignsList */
// $grid_CampaignsList_Header: 25px calc(50% - 23px) auto $grid_lastColumnWidth;
// $grid_CampaignsList_Row: calc(50% + 7px) auto 87px;
$grid_CampaignsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_CampaignsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
.ant-list.CampaignsList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_CampaignsList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_CampaignsList_Row;
    }
}
/* List - CompetitorsList */
// $grid_CompetitorsList_Header: 25px calc(50% - 23px) auto $grid_lastColumnWidth;
// $grid_CompetitorsList_Row: calc(50% + 7px) auto 52px;
$grid_CompetitorsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_CompetitorsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);

.ant-list.CompetitorsList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_CompetitorsList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_CompetitorsList_Row;
    }
}
/* List - UsersList */
// $grid_UsersList_Header: 25px calc(50% - 23px) auto $grid_lastColumnWidth;
// $grid_UsersList_Row: calc(50% + 7px) auto 92px;
$grid_UsersList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_UsersList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);

.ant-list.UsersList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_UsersList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_UsersList_Row;
    }
}
/* List - AdsList */
$grid_AdsList_Header: 25px auto 15% 15% 15% 190px 15% $grid_lastColumnWidth;
$grid_AdsList_MasteradRow_Columns: auto;
$grid_AdsList_MasteradRow_Rows: auto auto;
$grid_AdsList_MasteradFirstRow_Columns: auto 155px 510px $grid_lastColumnWidth;
$grid_AdsList_AdRow_Columns: auto 470px 190px 160px $grid_lastColumnWidth;
.ant-list.AdsList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_AdsList_Header;
        &::before,
        &::after {
            display: none;
        }
    }
    /* Rows */
    ul.ant-list-items > li.ant-list-item > div.social_AdsMasteradRow {
        grid-template-columns: $grid_AdsList_MasteradRow_Columns;
        grid-template-rows: $grid_AdsList_MasteradRow_Rows;
        cursor: pointer;
        > div:first-child {
            // border: 1px solid blue;
            display: grid !important;
            grid-template-columns: $grid_AdsList_MasteradFirstRow_Columns;
        }
        > div:not(:first-child) > div {
            // border: 1px solid purple;
            font-weight: normal;
            display: grid !important;
            grid-template-columns: $grid_AdsList_AdRow_Columns;
            > * {
                // border: 1px dotted black;
            }
        }
    }
    /* Caret Icon */
    i.anticon.anticon-caret-right,
    i.anticon.anticon-caret-down {
        margin-top: 1px !important;
        font-size: 1.2rem !important;
    }
}
/* List - InsightsList */
$grid_InsightsList_ShortWidth: 80px;
$grid_InsightsList_Masterposts_Header: 25px auto $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth 68px;
$grid_InsightsList_Masterposts_Row: auto $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth 54px;
$grid_InsightsList_Posts_Header: 25px auto 230px $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth;
$grid_InsightsList_Posts_Row: auto 230px $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
    $grid_InsightsList_ShortWidth;

.ant-list.InsightsList_Masterposts,
.ant-list.InsightsList_Posts {
    // border: 1px solid red;
    @extend .social_MainListScrolling;
    max-height: calc(100vh - 340px);
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_InsightsList_Masterposts_Header;
        .ant-col {
            text-align: center !important;
            &:nth-child(2) {
                text-align: left !important;
            }
        }
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_InsightsList_Masterposts_Row;
        div {
            text-align: center !important;
            &:nth-child(1) {
                text-align: left !important;
            }
        }
    }

    .ant-list-header .ant-row::before,
    .ant-list-header .ant-row::after {
        display: none;
    }
}
.ant-list.InsightsList_Posts {
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_InsightsList_Posts_Header;
        .ant-col {
            &:nth-child(2),
            &:nth-child(3) {
                text-align: left !important;
            }
        }
    }
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_InsightsList_Posts_Row;
        div {
            &:nth-child(1),
            &:nth-child(2) {
                text-align: left !important;
            }
        }
    }
}
/* List - MasterpostsTargetsList */
// $grid_MasterpostsTargetsList_Header: auto $grid_lastColumnWidth;
// $grid_MasterpostsTargetsList_Row: auto 30% 30% 52px;
$grid_MasterpostsTargetsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_MasterpostsTargetsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);

.ant-list.MasterpostsTargetsList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_MasterpostsTargetsList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_MasterpostsTargetsList_Row;
    }
    .ant-list-item-meta-description {
        padding: 0;
        // color: $color_Primary;
    }
    .ant-list-item-meta-title {
        // color: $color_Primary;
    }
    .social_QueuePastChannel {
    }
}
/* List - AccountQueueList */
// $grid_AccountsQueueList_Header: 30% 30% auto $grid_lastColumnWidth;
// $grid_AccountsQueueList_Row: 30% 30% auto 52px;
$grid_AccountsQueueList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.33, 0.33, 0.34)
);
$grid_AccountsQueueList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.33, 0.33, 0.34)
);
.ant-list.AccountsQueueList {
    max-height: 500px;
    overflow-y: auto;

    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_AccountsQueueList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_AccountsQueueList_Row;
    }
    .ant-list-item-meta-description {
        padding: 0;
        // color: $color_Primary;
    }
    .ant-list-item-meta-title {
        // color: $color_Primary;
    }
    .social_QueuePastChannel {
        margin-right: -50px;
        display: grid;
        grid-template-columns: auto 20px;
        &:hover {
            // background-color: rgba($color_Grey, 0.3);
        }
    }
}

/* List - AuditorsList */
// $grid_AuditorsList_Header: 23px calc(50% - 23px) auto $grid_lastColumnWidth;
// $grid_AuditorsList_Row: calc(50% + 7px) auto 90px;
$grid_AuditorsList_Header: mixin_get_listingHeaders(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);
$grid_AuditorsList_Row: mixin_get_listingRows(
    $grid_checkboxColumnWidth,
    $grid_lastColumnWidth,
    (0.5, 0.5)
);

.ant-list.AuditorsList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_AuditorsList_Header;
    }
    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_AuditorsList_Row;
    }
}

/* List - ScheduleList */
$grid_ScheduleList_Header: 24% 24% 32% 9%;
$grid_ScheduleList_Row: calc(25% - 9px) calc(25% - 9px) calc(33% - 3px)
    calc(16% - 9px);

.ant-list.ScheduleList {
    @extend .social_MainListScrolling;
    /* Header */
    .ant-list-header .ant-row:nth-child(1) {
        grid-template-columns: $grid_ScheduleList_Header;
    }

    /* Rows */
    ul.ant-list-items > li.ant-list-item {
        grid-template-columns: $grid_ScheduleList_Row;
    }
}

.ant-list.social_MasterpostsStatus {
    .ant-list-item-meta-description {
        padding-left: 0;
    }
}
.ReportsList,
.LibraryList {
    .ant-card {
        border: 3px solid #e8e8e8;
    }
}
/* List - InboxList */
//$grid_AccountsChannelsList_Header: 40% calc(60% - 276px) 276px;
//$grid_AccountsChannelsList_Row: 40% calc(60% - 276px) 280px;
.ant-list.InboxList {
    // border: 1px solid red;
    @extend .social_MainListScrolling;
    max-height: calc(100vh - 242px);
}

/* Schedule */
.social_Schedule {
    //@extend .social_MainListScrolling;

    .social_TabsOnlyIcons {
        .ant-tabs-tabpane {
            // border: 1px solid green;
            height: calc(100vh - 180px);
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
// Main Layout
.ant-layout-content {
    max-width: 1166px;
    margin: 0 auto !important;
    width: 100%;
    & > div {
        padding: 40px !important;
    }
}
/* Bulk actions */
.ant-list-header .ant-btn-group .ant-btn {
    font-weight: 500;
    font-size: 14px;
    &:nth-of-type(1) {
        padding-left: 0;
        margin-left: -10px;
    }
    .anticon {
        display: none;
    }
}

.ant-list-header {
    .ant-input-search {
        height: 25px;
        input {
            height: 25px;
        }
    }
}
.ant-list-item-meta-title {
    margin-bottom: 0;
}
/* Radio buttons */
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    border-radius: 0;
}
.ant-btn-group > .ant-btn:not(:first-child):not(:last-child) {
    // color: $color_Primary;
    font-weight: bold;
}

.ant-checkbox-inner {
    min-height: 16px !important;
}
/* Calendar */
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
    font-weight: bold;
    // color: $color_Primary;
    text-align: left;
    padding-left: 7px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    // border-top: 3px solid $color_Grey;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
    text-align: left;
    font-weight: bold;
    // color: $color_Primary;
}
.ant-fullcalendar-fullscreen
    .ant-fullcalendar-selected-day
    .ant-fullcalendar-date {
    // border-top: 3px solid $color-palette-category;
    background: white;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
    // background: rgba($color-palette-category, 0.1);
}
/* Divider */
.ant-divider-horizontal {
    text-transform: uppercase;
    font-size: 18px;
    text-align: left;
    &::before,
    &::after {
        display: none;
    }
    .ant-divider-inner-text {
        padding-left: 0;
        width: calc(100% - 40px);
        padding-right: 0;
        border-bottom: 1px solid #eee;
    }
    .ant-badge {
        margin-top: 2px;
        float: right;
    }
}
.ant-list-item-action i {
    font-size: 17px;
    color: #666;
}
.ant-menu-item .anticon {
    font-size: 16px;
}
.ant-collapse.social_AccordeonWithShade {
    .ant-collapse-item > .ant-collapse-header {
        background-color: #f7f7f7;
    }
}

// Error Notifications
.ant-notification-notice-description i {
    display: block;
    font-size: 12px;
    font-style: normal;
    color: #999;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px dotted #ddd;
}

// List Header

.social_ListHeader {
    // border: 1px solid red;
    > * {
        // outline: 1px solid;
    }
    > div:nth-of-type(1) {
        min-width: 370px;
        max-width: 370px;
        flex-grow: 0;
        padding-left: 13px;
        padding-top: 5px;
        .ant-btn {
            padding-left: 5px;
            padding-right: 5px;
            margin-right: 5px;
            > .anticon + span,
            > span + .anticon {
                margin-left: 3px;
            }
        }
    }
    > div:last-child() {
        min-width: 370px;
        max-width: 370px;
        text-align: right;
    }
}
.ant-list-header {
    background: #f5f5f5;
    height: $size_ListingRowHeight;
    padding: 0 !important;
    padding-left: 14px !important;
    align-items: center;
    .anticon {
        vertical-align: -6px;
    }
    > * {
        // outline: 1px solid red;
    }
    .ant-col {
        text-transform: capitalize;
    }
    .ant-row:nth-child(1) .ant-col:last-child() {
        position: relative;
        &::after {
            content: "";
            background: #e8e8e8;
            width: 1px;
            height: 50px;
            position: absolute;
            top: -13px;
            right: 200px;
            display: none; // temporary
        }
    }
}

.social_Dashboard
    .social_Dashboard_GraphSection
    div.social_Dashboard_Box
    .ant-list
    .ant-list-header
    > div {
    font-weight: 600;
}

// New + button on the header
.ant-page-header-heading .ant-btn-primary,
.ant-page-header-heading .ant-btn-default {
    width: 65px !important;
    display: flex;
    align-items: center;
    font-weight: 600;
    > span + .anticon {
        display: inline-block;
        position: absolute;
        right: 6px;
        top: 6px;
    }
}
.social_ListHeader {
    .ant-btn-primary {
        font-weight: 600;
        display: inline-flex;
        align-items: center;
    }
    .ant-input-affix-wrapper {
        width: 140px !important;
        .ant-input {
            height: 30px;
        }
    }
}

.ant-form-item-label > label {
    text-transform: capitalize;
    font-weight: 600;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
    display: none;
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
    padding-left: 0;
}
input[type="text"],
input[type="password"],
input[type="number"] {
    height: 35px;
}

.ant-modal .ant-btn {
    text-transform: capitalize !important;
}
.ant-list-footer {
    background: #f5f5f5;
}

.AdsList .ant-list-item {
    padding-top: 12px;
}
.ant-fullcalendar-calendar-body {
    padding: 8px 0px;
    margin-left: -8px;
    margin-right: -8px;
}
.ant-fullcalendar-header {
    padding: 11px 0px 11px 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    height: 280px;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    margin-top: -5px;
}
.ant-input,
.ant-input-affix-wrapper .ant-input {
    height: 40px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    font-size: 16px;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    margin-top: -7px;
}
.ant-page-header-heading
    .ant-page-header-heading-extra
    .social_extra_header
    > div
    > span.social_extra_header_worldName {
    position: absolute;
    left: calc(50vw - 100px);
    transform: translateX(-50%);
    opacity: 0;
}
.ant-layout-content .social_worldName {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -75px);
}
.ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item-active,
.ant-pagination-item-active:focus {
    background: transparent;
    border: 1px solid transparent;
    font-weight: 600;
}
// temporary
.react-joyride__beacon {
    display: none !important;
}
.ant-list-header .anticon svg {
    font-size: 12px;
}

// React Grid
.react-grid-placeholder {
    background: #666 !important;
}

.LibraryHeaader {
    > .ant-col {
        display: flex;
        align-items: center;
    }
    .ant-input,
    .ant-input-affix-wrapper .ant-input {
        height: 32px;
    }
}

// Capitalize Words
.social_Dashboard .social_Dashboard_RightSection .social_Dashboard_Box,
.social_Dashboard .ant-badge > h4,
.social_ListHeader > div:nth-of-type(1) .ant-btn,
.social_ListHeader .ant-btn-primary,
.ant-menu-inline > .ant-menu-item,
.ant-breadcrumb,
.social_ListHeader h3.ant-typography,
.ant-empty-description,
.LibraryHeaader .ant-btn,
.Settings h4,
.System h4,
.social_topRight_menu .ant-dropdown-menu-item,
.social_topRight_menu .ant-dropdown-menu-submenu-title,
// .ant-form-item-control .ant-form-extra,
.ant-divider-horizontal .ant-divider-inner-text,
.ant-input::-webkit-input-placeholder,
.ant-select-search--inline .ant-select-search__field,
.ant-modal .ant-modal-content .ant-modal-title,
.social_ReportModal h3,
.social_ReportModal .ant-collapse-header,
.social_ReportModal .ant-statistic-title {
    text-transform: capitalize;
}
