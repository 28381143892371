/* customize.scss */
@import "variables.scss";
/* Customization */

//@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap");

$fontFamily: "Montserrat", sans-serif;

$color_Primary: #182f44;
$color_Grey: #cdcac5;

$color_Error: #ec315e;

$color_Green: #42b047;
$color_SidebarBackground: #172e45;

body.customize_ {
    //     border: 1px solid #0ec1eb;

    background: url(./assets/images/customize_Background.jpg) center no-repeat;
    letter-spacing: -0.4px;
    font-family: $fontFamily;

    .ant-layout {
        background: #fff;
    }
    .ant-layout-sider,
    .ant-menu-dark,
    .ant-layout-sider-trigger {
        background: $color_Primary;
    }
    .ant-layout-sider-children {
        font-family: $fontFamily;
        font-size: 17px;
        font-weight: 800;
    }
    .ant-menu-dark.ant-menu-inline .ant-menu-item {
        font-size: 13.4px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
    }
    // .ant-layout-sider {
    //     flex: 0 0 230px;
    //     max-width: 230px;
    //     min-width: 230px;
    //     width: 230px;
    // }
    .logo {
        background: $color_SidebarBackground;
        border: 16px solid $color_SidebarBackground;
        margin: 0 0 0px 0;
        padding: 23px 16px;
        height: 114px;
        background-size: 127px 54px;
        background-repeat: no-repeat;
        background-position: 5px;
        //background-image: url(./assets/images/logo.png);
        background-image: url(./assets/images/sidebar_logo.svg);
        img {
            display: none;
        }
    }
    .ant-layout-sider-collapsed {
        .logo {
            background-image: url(./assets/images/logo_simplified.svg);
            background-position: center;
        }
    }

    .ant-page-header-heading-sub-title {
        margin-top: 3px;
        color: #ccc9c4;
        font-weight: bold;
        .ant-breadcrumb {
            font-size: 1rem;
            a {
                font-weight: bold;
                color: #ccc9c4;
            }
            & > span:last-child {
                color: #ccc9c4;
            }
        }
        .ant-breadcrumb-separator {
            color: #ccc9c4;
        }
    }

    .ant-layout-content .ant-btn-primary {
        padding-right: 12px;
        padding-bottom: 1px;
        font-weight: 500;
    }

    .social_Dashboard {
        // border: 1px solid red;

        // &::before {
        //     content: "BHRTsocial";
        //     position: absolute;
        //     // border: 1px solid green;
        //     top: 20px;
        //     left: 217px;
        //     background: #fff;
        //     color: #cdcbc5;
        //     padding: 10px 20px 0;
        //     font-size: 33px;
        //     font-weight: 800;
        //     letter-spacing: -0.1rem;
        // }

        .ant-alert {
            // display: none;
        }
        h4 {
            color: #193044;
            font-weight: 600;
            font-size: 16px;
        }

        .social_Dashboard_MainSection {
            // border: 1px solid blue;
            display: flex;
            > div:nth-child(1) {
                width: 100%;
                .ant-empty.ant-empty-normal {
                    margin: 0;
                }
            }
            .social_Dashboard_RightSection {
                // border: 1px solid blue;
                min-width: 204px;
                max-width: 204px;
                width: 204px;
                padding-right: 10px !important;
                padding-left: 30px !important;

                .social_Dashboard_Box {
                    //height: 280px !important;
                    // min-width: 300px;
                    // width: calc(25% - 15px) !important;
                    // width: 178px;
                    text-align: center;
                    position: relative;
                    font-size: 1.2rem;
                    font-weight: bold;
                    cursor: pointer;
                    margin-bottom: 10px;
                    padding: 28px;
                    line-height: 1.2;
                    height: 100px;
                }
                .social_Dashboard_Box:nth-of-type(1) {
                    color: white;
                    background-color: #00b6bd;
                }
                .social_Dashboard_Box:nth-of-type(2) {
                    color: white;
                    background-color: #00aeef;
                }
                .social_Dashboard_Box:nth-of-type(3) {
                    color: white;
                    background-color: #cdcbc5;
                    display: flex;
                    align-items: center;
                }
                .social_Dashboard_Box:nth-of-type(4) {
                    color: white;
                    background-color: #41ad49;
                    margin-right: 0 !important;
                }
                .social_Dashboard_Box:nth-of-type(5) {
                    color: white;
                    background-color: #00b6bd;
                }
            }
        }
        .social_Dashboard_TopSection {
            // border: 1px solid green;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 6px;

            div.ant-col:nth-child(1) {
                height: 100%;
                width: 100%;

                div.social_Dashboard_Box {
                    border: 3px solid #cdcbc5;
                    height: 117px;
                    // padding: 5px;
                    overflow-y: auto;
                    .ant-list-bordered {
                        border: none;
                        .ant-list-item {
                            cursor: pointer;
                            height: 37px;
                        }
                    }
                }
            }

            div.ant-col:nth-child(2) {
                height: 100%;
            }

            div:nth-child(2) .social_Dashboard_TopSection_Box {
                border: 3px solid #cdcbc5;
                height: calc(100% - 32px);
                padding: 5px;
                overflow-y: auto;
                .ant-list-bordered {
                    border: none;
                }
            }
            .ant-list-item {
                color: #193044;
                font-size: 16px;
                display: flex !important;
                justify-content: space-between !important;
            }
            .ant-tag {
                color: #fff;
                background-color: #dcddde;
                border-color: #cdcbc5;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .social_Dashboard_GraphSection {
            // border: 1px solid green;
            height: calc(100% - 137px);
            padding-top: 20px;
            display: flex;
            h4 {
                text-align: left;
                margin-top: 5px;
            }
            > div:nth-child(1) {
                // border: 1px dotted blue;
                padding-right: 50px;
                width: 200px;
                display: none;
            }
            > div:nth-child(2) {
                // border: 1px dotted blue;
                text-align: center;
                //width: calc(100% - 200px);
                width: 100%;
            }
            .ant-empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                // border: 1px solid;
                height: calc(100% - 30px);
                justify-content: center;
            }
            div.social_Dashboard_Box {
                /* List - grid_LatestMasterpostList_Row */
                $grid_LatestMasterpostList_Header: auto 70px 70px 70px;
                $grid_LatestMasterpostList_Row: auto 70px 70px 70px;
                .ant-list {
                    // border: 1px solid red;
                    @extend .social_MainListScrolling;

                    height: 367px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    /* Header */
                    .ant-list-header > div {
                        display: grid;
                        grid-template-columns: $grid_LatestMasterpostList_Header;
                        > :not(:first-child) {
                            text-align: center;
                        }
                        > :first-child {
                            text-align: left;
                        }
                    }
                    .ant-list-header > .ant-row::before {
                        display: none;
                    }

                    /* Rows */
                    ul.ant-list-items > li.ant-list-item {
                        grid-template-columns: $grid_LatestMasterpostList_Row;
                        > * {
                            text-align: right !important;
                        }
                        div:first-child {
                            // font-weight: bold;
                            color: $color_Primary;
                            text-align: left !important;
                        }
                        div:not(:first-child) {
                            text-align: center !important;
                        }
                    }
                }
            }
        }

        .social_Dashboard_BottomSection {
            margin-top: 10px;
            $bottomBoxHeight: calc(100vh - 850px);
            div.ant-col:nth-child(1) {
                .social_Dashboard_Box {
                    height: 195px;
                    height: $bottomBoxHeight;
                    min-height: 120px;

                    .ant-list {
                        height: 195px;
                        height: $bottomBoxHeight;
                        overflow-y: auto;

                        min-height: 120px;

                        .ant-list-item {
                            display: block;
                            line-height: 1.2;
                            height: 38px;
                            padding-top: 2px;
                            color: $color_Primary;
                            cursor: pointer;
                            .ant-tag {
                                margin-top: -10px !important;
                            }
                        }
                    }
                }
            }
            div.ant-col:nth-child(2) {
                .social_Dashboard_Box {
                    height: 195px;
                    height: $bottomBoxHeight;
                    min-height: 120px;

                    .ant-list {
                        height: 195px;
                        height: $bottomBoxHeight;

                        min-height: 120px;

                        overflow-y: auto;
                        cursor: pointer;
                        ul.ant-list-items li.ant-list-item {
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }

        .ant-list-header .ant-col {
            font-weight: 600;
            border: 1px solid red;
        }
    }

    .ant-page-header .ant-btn {
        width: 123px;
        text-align: left;
        padding-left: 35px;
        margin-right: 15px !important;
        .anticon-plus-circle {
            position: absolute;
            left: 10px;
            top: 8px;
        }
        .anticon.anticon-down {
            position: absolute;
            right: 10px;
            top: 9px;
        }
    }

    /* TopRight Menu */
    .ant-dropdown-menu.social_topRight_menu {
        width: 164px;
    }

    // Button
    button,
    [type="button"],
    .ant-button {
        border-radius: 0;
        // background-color: $color_Grey;
        // color: white;

        &:hover {
            border: 1px solid $color_Primary;
            color: $color_Primary;
        }
    }
    .ant-btn-link {
        color: $color_Primary;
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
            span {
                text-decoration: underline !important;
            }
        }
        &[disabled] {
            opacity: 0.5;
            span {
                text-decoration: line-through !important;
            }
        }
    }

    // Tag
    .ant-tag {
        border-radius: 0;
        border-width: 0;
        font-size: 14px;
        padding: 0 10px;
        margin-right: 2px !important;
        .anticon {
            margin-left: -5px;
        }
        .anticon-close {
            margin-left: 0px !important;
        }
    }

    // Modal
    .ant-modal-content {
        border-radius: 0;
        border: 3px solid $color_Grey;
        .ant-modal-close {
            &:hover {
                border: 0;
            }
        }
        .ant-modal-header {
            border-bottom: 0;
        }
    }

    // Input elements
    .ant-input {
        border-radius: 0;
        border: 1px solid $color_Grey;
        &:hover {
            border-color: $color_Primary !important;
        }
        &:focus {
            border-color: $color_Primary !important;
            box-shadow: 0 0 0 2px rgba($color_Primary, 0.2);
        }
    }
    // Error
    .has-error {
        .ant-input {
            border-color: $color_Error;
        }
        .ant-form-explain {
            color: $color_Error;
        }
    }

    // Menus
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
    .ant-tree li .ant-tree-node-content-wrapper:hover {
        background-color: rgba($color_Primary, 0.1);
    }

    // Tabs
    .ant-tabs-nav {
        .ant-tabs-tab-active {
            color: $color_Primary;
        }
        .ant-tabs-tab {
            &:hover {
                color: $color_Primary;
            }
        }
        .ant-tabs-ink-bar {
            background-color: $color_Primary;
        }
    }

    // Select
    .ant-select-selection {
        border-radius: 0;
        border-color: $color_Grey;
        &:hover {
            border-color: $color_Primary;
        }
    }

    // Steps
    .ant-steps-item-process .ant-steps-item-icon {
        border-color: $color_Primary;
        background-color: $color_Primary;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        border-color: $color_Primary;
        .ant-steps-icon {
            color: $color_Primary;
        }
    }
    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: $color_Primary;
    }

    // Input number
    .ant-input-number {
        border-radius: 0;
        border-color: $color_Grey;
        &:focus {
            box-shadow: 0 0 0 2px rgba($color_Primary, 0.2);
        }
    }
    .ant-input-number-focused {
        box-shadow: 0 0 0 2px rgba($color_Primary, 0.2);
    }

    // Slider
    .ant-slider-handle {
        border-color: rgba($color_Primary, 0.2);
        &:hover {
            border-color: $color_Primary;
        }
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: $color_Primary;
    }
    .ant-slider-handle:focus {
        box-shadow: 0 0 0 5px rgba($color_Primary, 0.2);
    }
    .ant-slider:hover .ant-slider-track {
        background-color: $color_Primary;
    }

    // List
    .ant-list-item-meta-title {
        a {
            color: rgba($color_Primary, 0.7);
            &:hover {
                color: $color_Primary;
            }
        }
    }

    // Breadcrump
    .ant-breadcrumb {
        color: $color_Grey;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
        border: 0;
    }

    //Checkbox
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: $color_Primary;
        border-color: $color_Primary;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: $color_Green;
    }

    // List
    .ant-list-bordered {
        border-radius: 0;
        border: 3px solid $color_Grey;
    }

    // Radio wrapper
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border-radius: 0;
        box-shadow: -1px 0 0 0 $color_Primary;
        color: $color_Primary;
        border-color: $color_Primary;
        font-weight: bold;
        &:hover {
            color: $color_Primary;
        }
    }

    // Card
    .ant-card {
        border-radius: 0 !important;
    }

    // Switch
    .ant-switch {
        background-color: $color_Grey;
        &:after {
            border-radius: 0;
        }
    }
    .ant-switch-checked {
        background-color: $color_Primary;
    }

    //theme update
    .ant-btn-primary {
        background-color: $color_Primary;
        border-color: $color_Primary;

        font-weight: 600;
        &:hover {
            color: white;
        }
        &[disabled] {
            border: 1px solid red;
        }
    }

    .ant-page-header {
        padding-left: 40px;
        padding-top: 33px;
    }

    .ant-tooltip {
        .ant-tooltip-inner {
            background-color: #fff;
            border: 3px solid $color_Primary;
            border-radius: 0;
            color: $color_Primary;
            white-space: pre-line;
            padding: 16px 18px;
        }
        .ant-tooltip-arrow::before {
            background-color: $color_Primary;
        }
    }

    .ant-btn-group > .ant-btn[disabled] {
        opacity: 0.3;
    }

    /* Form */
    .ant-form-item-label {
        line-height: 1.5;
        margin-top: 10px;
        font-weight: 600;

        & > label::after {
            display: none;
        }
    }
    .ant-modal-title {
        color: #2ab6e9;
        font-weight: 600;
        font-size: 18px;
    }
    .ant-tabs-nav .ant-tabs-tab {
        margin-right: 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        font-weight: 600;
        border-bottom: 2px solid $color_Primary;
    }
    .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
    .ant-divider-horizontal.ant-divider-with-text-right
        .ant-divider-inner-text {
        font-weight: 600;
    }
    .ant-modal-content {
        box-shadow: 0 0px 22px rgba(0, 0, 0, 0.5);
    }
    .ant-list-bordered.ant-list-sm .ant-list-footer,
    .ant-list-bordered.ant-list-sm .ant-list-header {
        padding-top: 16px;
    }
    .ant-radio-button-wrapper:first-child,
    .ant-radio-button-wrapper:last-child {
        border-radius: 0;
    }

    /* Menu */
    .ant-dropdown-menu {
        border-radius: 0;
        border: 3px solid $color_Grey;
        padding: 5px;
        .ant-dropdown-menu-item-divider {
            display: none;
        }
    }
    .ant-dropdown-menu-item {
        border-bottom: 1px solid $color_Grey;
    }
    .ant-dropdown-menu-item:last-child {
        border-bottom: none;
    }

    /* Header */
    .ant-page-header-heading {
        .ant-avatar {
            margin-right: 15px;
        }
        .ant-btn-primary {
            margin-right: 15px !important;
        }
        .ant-badge-count {
            right: 13px;
            background-color: $color-palette-category;
        }
    }

    /* Popover */
    .ant-popover {
        .ant-popover-content {
            border-radius: 0;
            border: 3px solid $color_Grey;
        }
        .ant-popover-arrow {
            border-top-color: $color_Grey;
            border-left-color: $color_Grey;
        }
    }

    /* Collapse */
    .ant-collapse {
        border-radius: 0;
        border: 3px solid $color_Grey;
        color: $color_Primary;
        > .ant-collapse-item > .ant-collapse-header {
            font-weight: bold;
            color: $color_Primary;
            background: white;
        }
        .ant-collapse-content > .ant-collapse-content-box {
            padding: 0;
            .ant-list-items {
                padding: 0;
                .ant-list-item {
                    padding: 10px 40px;
                    .ant-row {
                        &::before,
                        &::after {
                            display: none;
                        }
                        display: grid !important;
                        justify-content: stretch;
                        grid-template-columns: 25% auto auto 25px;
                        grid-template-rows: auto;
                        .ant-col {
                            /*border: 1px solid red;*/
                            width: unset !important;
                            .social_QueuePastChannel {
                                display: flex !important;
                                flex-direction: row;
                                justify-content: flex-end;
                            }
                        }
                    }
                    .social_AccountQueuePastContainer {
                        grid-template-columns: 25% auto auto;
                    }
                }
            }
        }
    }

    /* List */
    .ant-list-header .ant-col {
        // font-weight: bold;
        text-transform: uppercase;
        color: $color_Primary;
    }
    .ant-list-bordered.ant-list-sm .ant-list-item:hover {
        background-color: rgba($color_Grey, 0.1);
    }

    /* List - Global */
    .ant-list {
        // border: 1px solid red;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            // border: 1px solid green;
            display: grid !important;

            grid-template-rows: auto;
            &::before {
                display: none;
            }
            .ant-col {
                width: unset !important;
                padding: 0 !important;
                text-align: left !important;
            }
        }

        /* Rows */
        ul.ant-list-items {
            padding: 0 0px;
            & > li.ant-list-item {
                text-align: left;
                padding-left: 13px;
                padding-right: 13px;

                grid-template-rows: auto;
                column-gap: 0;
                justify-items: stretch;
                display: grid;
                align-items: center;
                justify-content: stretch;

                & > * {
                    // border: 1px solid red;
                    width: unset !important;
                    flex: unset !important;
                    display: unset !important;
                    text-align: left !important;
                    &.ant-list-item-action {
                        margin-left: 0;
                        & > li:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    .social_MainListScrolling {
        max-height: calc(100vh - 280px);
        display: flex;
        flex-direction: column;
        /* Body */
        > div:nth-child(2) {
            overflow-y: auto;
            overflow-x: hidden;
        }
        ul.ant-list-items > li.ant-list-item {
            align-items: start;
        }
        .ant-list-item-action .ant-btn-link {
            height: unset;
        }
    }
    /* List - AccountsList */
    $grid_AccountsList_Header: 23px 250px auto 190px 171px;
    $grid_AccountsList_Row: 275px auto 190px 174px;
    .ant-list.AccountsList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;

        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_AccountsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_AccountsList_Row;
        }

        ul.ant-list-items > li.ant-list-item .ant-breadcrumb {
            color: $color_Primary;
            & > span:last-child {
                color: $color_Primary;
            }
        }
        .ant-list-item-meta-title a {
            color: $color_Primary;
            // font-weight: bold;
        }
    }

    /* List - AccountsChannelsList */
    $grid_AccountsChannelsList_Header: 40% auto 171px;
    $grid_AccountsChannelsList_Row: 40% auto 174px;
    .ant-list.AccountsChannelsList {
        // border: 1px solid red;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_AccountsChannelsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_AccountsChannelsList_Row;
        }
    }

    /* List - TagsList */
    $grid_TagsList_Header: 25px calc(50% - 23px) auto 49px;
    $grid_TagsList_Row: 50% auto 52px;
    .ant-list.TagsList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;

        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_TagsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_TagsList_Row;
        }
    }

    /* List - MasterpostsList */

    $grid_MasterpostsList_Header: 25px 250px auto 195px 150px 189px;
    $grid_MasterpostsList_Row: 280px auto 195px 150px 192px;
    .ant-list.MasterpostsList {
        @extend .social_MainListScrolling;

        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_MasterpostsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_MasterpostsList_Row;
        }
    }

    /* List - CategoriesList */
    $grid_CategoriesList_Header: 25px calc(40% - 25px) auto 84px;
    $grid_CategoriesList_Row: 40% auto 87px;
    .ant-list.CategoriesList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_CategoriesList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_CategoriesList_Row;
        }
    }

    /* List - CampaignsList */
    $grid_CampaignsList_Header: 25px calc(40% - 25px) auto 83px;
    $grid_CampaignsList_Row: 40% auto 87px;
    .ant-list.CampaignsList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_CampaignsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_CampaignsList_Row;
        }
    }

    /* List - CompetitorsList */
    $grid_CompetitorsList_Header: 25px calc(40% - 25px) auto 49px;
    $grid_CompetitorsList_Row: 40% auto 52px;
    .ant-list.CompetitorsList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_CompetitorsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_CompetitorsList_Row;
        }
    }

    /* List - UsersList */
    $grid_UsersList_Header: 25px calc(40% - 25px) auto 92px;
    $grid_UsersList_Row: 40% auto 92px;
    .ant-list.UsersList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_UsersList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_UsersList_Row;
        }
    }

    /* List - AdsList */
    $grid_AdsList_Header: 25px auto 15% 15% 15% 190px 15% 40px;
    $grid_AdsList_MasteradRow_Columns: auto;
    $grid_AdsList_MasteradRow_Rows: auto auto;
    $grid_AdsList_MasteradFirstRow_Columns: auto 155px 510px 40px;
    $grid_AdsList_AdRow_Columns: auto 470px 190px 160px 40px;
    .ant-list.AdsList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;

        color: $color_Primary;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_AdsList_Header;
            &::before,
            &::after {
                display: none;
            }
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item > div.social_AdsMasteradRow {
            grid-template-columns: $grid_AdsList_MasteradRow_Columns;
            grid-template-rows: $grid_AdsList_MasteradRow_Rows;
            // font-weight: bold;
            // border: 1px solid red;
            cursor: pointer;
            > div:first-child {
                // border: 1px solid blue;
                display: grid !important;
                grid-template-columns: $grid_AdsList_MasteradFirstRow_Columns;
            }
            > div:not(:first-child) > div {
                // border: 1px solid purple;
                font-weight: normal;
                display: grid !important;
                grid-template-columns: $grid_AdsList_AdRow_Columns;
                > * {
                    // border: 1px dotted black;
                }
            }
        }

        /* Caret Icon */
        i.anticon.anticon-caret-right,
        i.anticon.anticon-caret-down {
            margin-top: 1px !important;
            font-size: 1.2rem !important;
        }
    }

    /* List - InsightsList */
    $grid_InsightsList_ShortWidth: 80px;
    $grid_InsightsList_Masterposts_Header: 25px auto
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth 68px;
    $grid_InsightsList_Masterposts_Row: auto $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth 54px;
    $grid_InsightsList_Posts_Header: 25px auto 230px
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth;
    $grid_InsightsList_Posts_Row: auto 230px $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth $grid_InsightsList_ShortWidth
        $grid_InsightsList_ShortWidth;

    .ant-list.InsightsList_Masterposts,
    .ant-list.InsightsList_Posts {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        max-height: calc(100vh - 340px);
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_InsightsList_Masterposts_Header;
            .ant-col {
                text-align: center !important;
                &:nth-child(2) {
                    text-align: left !important;
                }
            }
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_InsightsList_Masterposts_Row;
            div {
                text-align: center !important;
                &:nth-child(1) {
                    text-align: left !important;
                }
            }
        }

        .ant-list-header .ant-row::before,
        .ant-list-header .ant-row::after {
            display: none;
        }
    }
    .ant-list.InsightsList_Posts {
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_InsightsList_Posts_Header;
            .ant-col {
                &:nth-child(2),
                &:nth-child(3) {
                    text-align: left !important;
                }
            }
        }
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_InsightsList_Posts_Row;
            div {
                &:nth-child(1),
                &:nth-child(2) {
                    text-align: left !important;
                }
            }
        }
    }

    /* List - MasterpostsTargetsList */
    $grid_MasterpostsTargetsList_Header: auto 49px;
    $grid_MasterpostsTargetsList_Row: auto 30% 30% 52px;
    .ant-list.MasterpostsTargetsList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_MasterpostsTargetsList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_MasterpostsTargetsList_Row;
        }
        .ant-list-item-meta-description {
            padding: 0;
            color: $color_Primary;
        }
        .ant-list-item-meta-title {
            color: $color_Primary;
        }
        .social_QueuePastChannel {
        }
    }

    /* List - AccountQueueList */
    $grid_AccountsQueueList_Header: 30% 30% auto 49px;
    $grid_AccountsQueueList_Row: 30% 30% auto 52px;
    .ant-list.AccountsQueueList {
        // border: 1px solid red;
        max-height: 500px;
        overflow-y: auto;

        @extend .social_MainListScrolling;
        /* Header */
        .ant-list-header .ant-row:nth-child(2) {
            grid-template-columns: $grid_AccountsQueueList_Header;
        }

        /* Rows */
        ul.ant-list-items > li.ant-list-item {
            grid-template-columns: $grid_AccountsQueueList_Row;
        }
        .ant-list-item-meta-description {
            padding: 0;
            color: $color_Primary;
        }
        .ant-list-item-meta-title {
            color: $color_Primary;
        }
        .social_QueuePastChannel {
            margin-right: -50px;
            display: grid;
            grid-template-columns: auto 20px;
            &:hover {
                background-color: rgba($color_Grey, 0.3);
            }
        }
    }

    // /* List - AuditorsList */
    // $grid_AuditorsList_Header: 23px 250px auto 190px 130px 171px;
    // $grid_AuditorsList_Row: 275px auto 190px 130px 174px;
    // .ant-list.AuditorsList {
    //     border: 1px solid red !important;
    //     @extend .social_MainListScrolling;

    //     /* Header */
    //     .ant-list-header .ant-row:nth-child(2) {
    //         grid-template-columns: $grid_AuditorsList_Header;
    //     }

    //     /* Rows */
    //     ul.ant-list-items > li.ant-list-item {
    //         grid-template-columns: $grid_AuditorsList_Row;
    //     }

    //     ul.ant-list-items > li.ant-list-item .ant-breadcrumb {
    //         color: $color_Primary;
    //         & > span:last-child {
    //             color: $color_Primary;
    //         }
    //     }
    //     .ant-list-item-meta-title a {
    //         color: $color_Primary;
    //         // font-weight: bold;
    //     }
    // }

    .ant-list.social_MasterpostsStatus {
        .ant-list-item-meta-description {
            padding-left: 0;
        }
    }

    .ReportsList,
    .LibraryList {
        //@extend .social_MainListScrolling;
        .ant-card {
            border: 3px solid $color_Grey;
        }
    }

    /* List - InboxList */
    //$grid_AccountsChannelsList_Header: 40% calc(60% - 276px) 276px;
    //$grid_AccountsChannelsList_Row: 40% calc(60% - 276px) 280px;
    .ant-list.InboxList {
        // border: 1px solid red;
        @extend .social_MainListScrolling;
        max-height: calc(100vh - 242px);
    }

    /* Schedule */
    .social_Schedule {
        // border: 1px solid red;
        //@extend .social_MainListScrolling;

        .social_TabsOnlyIcons {
            .ant-tabs-tabpane {
                // border: 1px solid green;
                height: calc(100vh - 180px);
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }

    // $size_AdsListColumnWidth_Last: 40px;
    // $size_AdsListColumnWidth_2: calc(20% - #{$size_AdsListColumnWidth_Last});
    // $size_AdsListColumnWidth_3: calc(20% - #{$size_AdsListColumnWidth_Last});
    // $size_AdsListColumnWidth_4: calc(20% - #{$size_AdsListColumnWidth_Last});
    // $size_AdsListColumnWidth_5: calc(20% - #{$size_AdsListColumnWidth_Last});
    // $size_AdsListColumnWidth_6: calc(20% - #{$size_AdsListColumnWidth_Last});

    // .ant-list.AdsList {
    //     .ant-list-header .ant-row:nth-child(2) {
    //         .ant-col:nth-child(1) {
    //             min-width: 25px;
    //         }
    //         .ant-col:nth-child(2) {
    //             // border: 1px dashed black;
    //             margin-right: 0 !important;
    //             width: calc(#{$size_AdsListColumnWidth_2}) !important;
    //             min-width: calc(#{$size_AdsListColumnWidth_2}) !important;
    //         }
    //         .ant-col:nth-child(3) {
    //             // border: 1px dashed black;
    //             margin-right: 0 !important;
    //             width: calc(#{$size_AdsListColumnWidth_3}) !important;
    //             min-width: calc(#{$size_AdsListColumnWidth_3}) !important;
    //         }
    //         .ant-col:nth-child(4) {
    //             // border: 1px dashed black;
    //             margin-right: 0 !important;
    //             width: calc(#{$size_AdsListColumnWidth_4}) !important;
    //             min-width: calc(#{$size_AdsListColumnWidth_4}) !important;
    //         }
    //         .ant-col:nth-child(5) {
    //             // border: 1px dashed black;
    //             margin-right: 0 !important;
    //             width: calc(#{$size_AdsListColumnWidth_5}) !important;
    //             min-width: calc(#{$size_AdsListColumnWidth_5}) !important;
    //         }
    //         .ant-col:nth-child(6) {
    //             // border: 1px dashed black;
    //             margin-right: 0 !important;
    //             width: calc(#{$size_AdsListColumnWidth_6}) !important;
    //             min-width: calc(#{$size_AdsListColumnWidth_6}) !important;
    //         }

    //         .ant-col:last-child {
    //             // border: 1px dashed black;
    //             width: $size_AdsListColumnWidth_Last !important;
    //             min-width: $size_AdsListColumnWidth_Last !important;
    //             // padding-left: 20px;
    //         }
    //     }
    //     //border: 1px solid red;
    //     /* Rows */
    //     ul.ant-list-items > li.ant-list-item > div:nth-child(1) {
    //         // border: 1px solid red;

    //         flex: unset !important;
    //         margin: 0 !important;
    //         // width: calc(#{$size_AdsListColumnWidth_2} + 25px) !important;
    //         // min-width: calc(#{$size_AdsListColumnWidth_2} + 25px) !important;
    //         width: 100% !important;
    //         min-width: 100% !important;

    //         > div.ant-list-item-meta-content {
    //             width: 100%;
    //         }
    //         > div:nth-child(2) {
    //             > div {
    //                 > div:nth-child(1) {
    //                     // border: 1px solid greenyellow;
    //                     flex: unset !important;
    //                     width: calc(20% + 10px) !important;
    //                     min-width: calc(20% + 10px) !important;
    //                 }
    //                 > div:nth-child(2) {
    //                     // border: 1px solid red;
    //                     flex: unset !important;
    //                     width: calc(20% - 10px) !important;
    //                     min-width: calc(20% - 10px) !important;
    //                 }
    //                 > div:nth-child(3) {
    //                     // border: 1px solid red;
    //                     flex: unset !important;
    //                     width: calc(20% - 10px) !important;
    //                     min-width: calc(20% - 10px) !important;
    //                 }
    //                 > div:nth-child(4) {
    //                     // border: 1px solid blue;
    //                     flex: unset !important;
    //                     width: calc(20% - 10px) !important;
    //                     min-width: calc(20% - 10px) !important;
    //                 }
    //                 > div:nth-child(5) {
    //                     // border: 1px solid purple;
    //                     flex: unset !important;
    //                     width: calc(20% - 10px) !important;
    //                     min-width: calc(20% - 10px) !important;
    //                 }
    //                 > div:nth-child(6) {
    //                     flex: unset !important;
    //                     width: 30px !important;
    //                     min-width: 30px !important;
    //                 }
    //             }
    //         }
    //     }
    //     // ul.ant-list-items > li.ant-list-item > div:nth-child(2) {
    //     //     border: 1px solid red;
    //     //     flex: unset !important;
    //     //     margin: 0 !important;
    //     //     width: calc(#{$size_AdsListColumnWidth_3} + 25px) !important;
    //     //     min-width: calc(#{$size_AdsListColumnWidth_3} + 25px) !important;
    //     // }
    // }

    .social_Empty {
        /*border: 1px solid red;*/
        .ant-empty-image {
            svg {
                display: none;
            }
            background-image: url(./assets/images/nodata_image.svg);
            background-size: 220px;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
        .ant-empty-description {
            display: none;
        }
    }

    // Main Layout
    .ant-layout-content {
        max-width: 1166px;
        margin: 0 auto !important;
        width: 100%;
        & > div {
            padding: 40px !important;
        }
    }
    .ant-list-bordered .ant-list-item {
        padding-right: 10px;
        padding-left: 10px;
    }

    /* Joyride - onboarding */
    .__floater {
        .react-joyride__tooltip {
            border-radius: 0 !important;
            border: 3px solid $color_Primary;
            & > button {
                height: 50px !important;
            }

            & > div:nth-child(2) {
                > button[data-action="back"] {
                    color: $color_Primary !important;
                }
                > button[data-action="primary"] {
                    border-radius: 0 !important;
                    background-color: $color-palette-category !important;
                    outline: none !important;
                }
            }
        }
        .__floater__arrow {
            position: relative;
            span {
                bottom: 10px;
                svg {
                    display: none !important;
                }
                &::before {
                    content: "";

                    width: 10px;
                    height: 10px;
                    position: absolute;

                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent $color_Primary
                        transparent;
                }
            }
        }
    }

    /* Bulk actions */
    .ant-list-header .ant-btn-group .ant-btn {
        font-weight: 500;
        font-size: 14px;
        &:nth-of-type(1) {
            padding-left: 0;
            margin-left: -10px;
        }
        .anticon {
            display: none;
        }
    }

    /* Tools/List */
    .ant-list-item-action {
        .ant-btn-link,
        & > li {
            text-transform: uppercase;
            font-size: 12px;
        }
    }
    .ant-list-header {
        .ant-input-search {
            height: 25px;
            input {
                height: 25px;
            }
        }
    }
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }

    /* Buttons */
    .ant-btn-ghost {
        border: 0;
        outline: 0;
        box-shadow: none;
        border-bottom: 1px solid $color_Primary;
        &:hover {
            border: 0;
            outline: 0;
            box-shadow: none;
            border-bottom: 1px solid $color_Green;
            color: $color_Green;
        }
    }

    /* Icon More */
    .anticon-more {
        font-size: 1.2rem;
        padding: 0px 0px !important;
    }

    /* Radio buttons */
    .ant-btn-group > .ant-btn:first-child:not(:last-child),
    .ant-btn-group > .ant-btn:last-child:not(:first-child) {
        border-radius: 0;
    }
    .ant-btn-group > .ant-btn:not(:first-child):not(:last-child) {
        color: $color_Primary;
        font-weight: bold;
    }

    /* Calendar */
    .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
        font-weight: bold;
        color: $color_Primary;
        text-align: left;
        padding-left: 7px;
    }
    .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
    .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
        border-top: 3px solid $color_Grey;
    }
    .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
        text-align: left;
        font-weight: bold;
        color: $color_Primary;
    }
    .ant-fullcalendar-fullscreen
        .ant-fullcalendar-selected-day
        .ant-fullcalendar-date {
        border-top: 3px solid $color-palette-category;
        background: white;
    }
    .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
        background: rgba($color-palette-category, 0.1);
    }

    /* New Masterposts Filter */
    .social_filterChannelContainer {
        border: 1px solid $color_Grey;
        display: flex;
        flex-direction: row;
        padding: 2px 6px 2px 0px;
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        .ant-input-search {
            line-height: 1;
            input {
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                height: 26px;
                min-height: unset;
            }
            .ant-input-suffix {
                top: 13px;
            }
        }
        div {
            line-height: 0;
            i.anticon {
                font-size: 20px !important;
                margin-top: 3px;
            }
        }
    }

    /* Divider */
    .ant-divider-horizontal {
        text-transform: uppercase;
        font-size: 18px;
        text-align: left;
        &::before,
        &::after {
            display: none;
        }
        .ant-divider-inner-text {
            padding-left: 0;
            width: calc(100% - 40px);
            padding-right: 0;
            border-bottom: 1px solid #eee;
        }
        .ant-badge {
            margin-top: 2px;
            float: right;
        }
    }

    /* ChannelsList */
    .social_ChannelsTree .ant-tree-node-content-wrapper {
        width: calc(100% - 46px);
        padding-right: 0;
        .anticon {
            margin-left: 1px !important;
            margin-top: 5px !important;
        }
    }

    /* TabsBubbles */
    .social_TabsBubbles
        > .ant-tabs-bar
        > .ant-tabs-nav-container
        > .ant-tabs-nav-wrap
        > .ant-tabs-nav-scroll
        > .ant-tabs-nav
        > div
        > .ant-tabs-tab {
        background-color: $color_Grey;
        &.ant-tabs-tab-active {
            background-color: $color_Primary;
        }
    }
    button.social_TabsBubbles_NewButton {
        color: white;
        background-color: $color_Grey;
        border: none !important;
        margin-right: 10px;
        border-radius: 100%;
        width: 47px;
        height: 47px;
        font-size: 1.2rem;
        line-height: 1.2;

        bottom: -65px;
        left: 57px;

        span {
            display: none;
        }
        i {
            // right: -5px;
            // top: 13px;
            // position: absolute;
            // display: none;
        }
    }

    // .ant-notification {
    //     border: 3px solid $color_Grey;
    //     border-radius: 0;
    // }

    .social_ErrorNotification {
        .ant-tooltip-inner {
            border-color: $color-palette-error;
            color: $color-palette-error;
        }
        .ant-tooltip-arrow::before {
            background-color: $color-palette-error;
        }
    }
    .ant-list-item-action i {
        font-size: 17px;
        color: #666;
    }
    .ant-menu-item .anticon {
        font-size: 16px;
    }

    /* Reports */
    .social_ReportModal {
        .ant-collapse-header {
            .ant-btn-link {
                margin-right: 0 !important;
            }
        }
        .ant-collapse-content {
            padding: 20px;
        }
    }

    .social_TabsLeft {
        .ant-tabs-left-bar .ant-tabs-tab:last-child,
        .ant-tabs-right-bar .ant-tabs-tab:last-child {
            width: 175px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    // AdsModal Accordeonbody.customize .ant-collapse > .ant-collapse-item > .ant-collapse-header
    .ant-collapse.social_AccordeonWithShade {
        .ant-collapse-item > .ant-collapse-header {
            background-color: #f7f7f7;
        }
    }
}
