@import "mixins.scss";
/* Phone */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    /* CSS Rules Here */
    body {
        // border: 1px solid red;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        //  Mobile Hamburger menu
        .social_mobile_menu {
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;
            color: "#3f3f3f";
            font-size: 1.6rem;
            padding: 20px 20px 20px 0px;
            @include mixin_animation(all);
        }
        .social_mobile_nav_expanded {
            .social_mobile_menu {
                color: white;
            }
        }

        // Navigation
        .ant-layout {
            aside {
                position: fixed;
                z-index: 99;
                flex: 0 0 100vw !important;
                max-width: 100vw !important;
                min-width: 100vw !important;
                width: 100vw !important;
                height: 100vh !important;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
                transform: translateX(calc(-100% - 30px));
                .ant-layout-sider-trigger {
                    display: none;
                }
                .ant-menu.ant-menu-root {
                    overflow-y: auto;
                    max-height: 100%;
                    height: calc(100vh - 115px);
                }
                @include mixin_animation(all);
            }
            &.social_mobile_expanded {
                aside {
                    transform: translateX(0px);
                }
            }
            .ant-page-header-heading-sub-title {
                width: calc(100vw - 220px);
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        // Page header
        .ant-page-header {
            background: white;
            padding-top: 15px;

            .social_extra_header {
                display: flex;
                > div > span.ant-badge:nth-of-type(1) {
                    position: absolute;
                    top: 15px;
                    right: 50px;
                }
                > div > button.ant-btn.ant-dropdown-trigger {
                    width: 50px;
                    padding: 0;
                    position: absolute;
                    top: 17px;
                    right: 85px;
                    > span {
                        display: none;
                    }
                }
                > div > span.ant-badge:nth-of-type(2) {
                    // border: 1px solid red;
                    position: fixed;
                    z-index: 99;
                    top: 20px;
                    width: 33px;
                    left: calc(-100vw - 20px);
                    transform: translateX(-100%);
                    @include mixin_animation(all);
                }
            }
        }
        // Page content
        .ant-layout-content {
            height: calc(100vh - 100px);
            overflow-x: hidden;
            overflow-y: auto;
            > div {
                height: auto !important;
                min-height: 100% !important;
            }
        }
        // Page footer
        .ant-layout-footer {
            display: none;
        }

        .social_mobile_nav_expanded
            .ant-page-header
            .social_extra_header
            > div
            > span.ant-badge:nth-of-type(2) {
            left: 20px;
            transform: translateX(0);
        }

        // Dashboard
        .ant-layout-content > div {
            padding: 20px !important;
        }
        .social_Dashboard {
            .social_Dashboard_MainSection {
                flex-direction: column;
                margin-left: -20px;
                margin-right: -20px;
            }
            .social_Dashboard_GraphSection {
                height: 30vh;
            }
            .social_Dashboard_RightSection {
                display: none;
            }
            .social_Dashboard_BottomSection {
                > div:nth-of-type(2) {
                    padding-top: 10px;
                }
            }
        }

        // Popups
        .ant-modal {
            // border: 1px solid red;
            width: calc(100vw - 10px) !important;
            height: calc(100vh - 15px) !important;
            transform-origin: 0 !important;
            transform: translate(0, 0);
            position: fixed;
            top: 7px !important;
            left: 9px;
            margin: 0;
            padding-bottom: 0;
            display: flex !important;
            min-width: unset !important;
            .ant-modal-content {
                width: 100%;
                // border: 1px solid red;
                display: flex;
                flex-direction: column;
                .ant-modal-body {
                    // border: 1px solid red;
                    max-height: calc(100vh - 70px);
                    overflow-y: auto;
                    flex-grow: 1;
                    .ant-steps {
                        display: none;
                    }
                    form > div > div > div {
                        width: 100% !important;
                        padding: 0 !important;
                    }
                }
            }
        }

        // Listings
        .ant-layout-content {
            button.ant-btn.ant-btn-primary {
                position: fixed;
                bottom: 20px;
                right: 20px;
                font-size: 0;
                border-radius: 100%;
                width: 50px;
                height: 50px;
                z-index: 1;
                box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
                span {
                    display: none;
                }
            }
            .ant-list {
                max-height: calc(100vh - 220px) !important;
            }
            .ant-list-header {
                .ant-input-search {
                    width: 100px !important;
                }
                .ant-row:nth-of-type(2) {
                    display: none !important;
                }
            }

            .ant-list-item {
                // border: 1px solid red;
                grid-template-columns: 50% 50% !important;
                grid-template-rows: auto !important;
                grid-row-gap: 10px;
            }
            .ant-list-item-action > li {
                padding: 2px 10px !important;
                &:first-of-type {
                    padding-left: 0 !important;
                }
                &:last-of-type {
                    padding-right: 0 !important;
                }
            }
            /* Specific lists */
            .ant-list.AccountsList ul.ant-list-items > li.ant-list-item {
                position: relative;
                padding-bottom: 40px;
                > *.ant-list-item-action {
                    position: absolute;
                    bottom: 5px;
                    right: 11px;
                }
            }

            .ant-list.TagsList ul.ant-list-items > li.ant-list-item {
                grid-template-columns: 50% auto 56px !important;
            }
            /* Competitors */
            .ant-list.CompetitorsList ul.ant-list-items > li.ant-list-item {
                grid-template-columns: 50% auto 56px !important;
            }
            /* Insights */
            .InsightsList button.ant-btn.ant-btn-primary {
                width: 40px;
                height: 40px;
                font-size: 1rem;
                box-shadow: none;
                padding: 7px 11px;
                border-radius: 20px;
            }
            .InsightsList_Masterposts,
            .InsightsList_Posts {
                .ant-calendar-picker {
                    margin-top: 10px;
                    width: calc(100% + 4px);
                }
            }
            /* Library */
            .LibraryHeaader {
                .ant-input-affix-wrapper {
                    width: calc(100vw - 280px) !important;
                }
            }
            .LibraryList {
                .ant-card {
                    width: calc(100%) !important;
                    margin-right: 0 !important;
                }
                .ant-card-small {
                    width: calc(50% - 8px) !important;
                    margin-right: 15px !important;
                    &:nth-child(even) {
                        margin-right: 0 !important;
                    }
                }
            }
            /* Reports */
            .ReportsList {
                button.ant-btn.ant-btn-primary {
                    position: relative;
                    bottom: unset;
                    right: unset;
                    font-size: unset;
                    font-size: 14px;
                    border-radius: 4px;
                    height: 32px;
                    box-shadow: none;
                    width: auto;
                    span {
                        display: block;
                    }
                }
                .ant-card {
                    width: 100% !important;
                    margin-right: 0 !important;
                }
            }
            /* Users */
            .UsersList {
                .ant-list-header .ant-btn-group {
                    display: flex;
                    flex-flow: wrap;
                    .ant-btn {
                        font-weight: normal;
                    }
                }
                ul.ant-list-items > li.ant-list-item {
                    display: grid;
                    grid-template-columns: auto 75px 84px !important;
                }
            }
            /* Settings */
            .Settings {
                .social_SettingsSkin {
                    width: 70.83333333%;
                }
            }
            /* AccountsChannels */
            .ant-list.AccountsChannelsList
                ul.ant-list-items
                > li.ant-list-item {
                grid-template-columns: auto 190px !important;
                > div:nth-of-type(1) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > div:nth-of-type(2) {
                    display: none !important;
                }
            }
        }
        /* AdsModal */
        .AdsModal {
            .ant-modal-content .ant-modal-body form > div {
                height: unset;
                max-height: unset;
                > div > div {
                    //ant-row
                    margin: 0 !important;
                    display: flex;
                    flex-direction: column;
                    > .ant-col {
                        width: 100% !important;
                        padding: 0 !important;
                    }
                }
            }
        }
        /* Popup tabs */
        .ant-tabs-tabpane {
            > div:nth-of-type(2),
            div:nth-of-type(4),
            div:nth-of-type(6),
            div:nth-of-type(8) {
                width: 100% !important;
            }
        }
        /* AdsList */
        .AdsList {
            .ant-list-item {
                grid-template-columns: auto !important;
            }
            .social_AdsMasteradRow {
                > div:first-child {
                    grid-template-columns: auto 50%;
                }
                div:nth-of-type(3),
                div:nth-of-type(4) {
                    display: none;
                }
                div:not(:first-child) > div {
                    grid-template-columns: auto 50% !important;
                    width: calc(100vw - 70px);
                    > div:nth-of-type(2),
                    > div:nth-of-type(3) {
                        display: none;
                    }
                }
            }
        }
        /* Calendar */
        .social_ScheduleDate_Listing {
            li.ant-list-item {
                grid-template-columns: auto !important;
            }
        }
        .social_Schedule {
            // border: 1px solid blue;
            .ant-btn-group {
                width: 100%;
                margin-top: -10px;
                > .ant-btn:not(:first-child):not(:last-child) {
                    width: calc(100% - 62px);
                }
            }
            .ant-tabs-tabpane > div:nth-of-type(2) {
                display: none;
            }
            .ant-fullcalendar-header {
                // border: 1px solid red;
                padding: 10px 0;
            }
            .social_ScheduleFilterContainer {
                padding: 0px !important;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                background: white;
                border: 1px solid #d9d9d9;
                // width: calc(100vw - 40px) !important;
                // height: calc(100vh - 110px) !important;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                .ant-btn.ant-btn-icon-only {
                }
                > div {
                    // padding: 10px 20px !important;
                    width: unset !important;
                }
                .social_TabsOnlyIcons .ant-tabs-tabpane {
                    height: calc(100vh - 220px);
                }
            }
        }
    }
}
