@mixin mixin_animation($property, $time: "") {
    -webkit-transition: $property 0.3s ease-out;
    -moz-transition: $property 0.3s ease-out;
    -o-transition: $property 0.3s ease-out;
    transition: $property 0.3s ease-out;

    @if $time != "" {
        -webkit-transition: $property $time ease-out;
        -moz-transition: $property $time ease-out;
        -o-transition: $property $time ease-out;
        transition: $property $time ease-out;
    }
}

$font-sizes: (16, 18, 20, 22);

@each $size in $font-sizes {
    .font-size-#{$size} {
        font-size: #{$size}px;
        line-height: #{$size + 2}px;
    }
}

@function mixin_get_listingHeaders(
    $checkboxColumnWidth,
    $lastColumnWidth,
    $sizesInPercentage
) {
    $result: $checkboxColumnWidth;
    @each $size in $sizesInPercentage {
        $result: $result +
            " " +
            calc(
                (100% - #{$lastColumnWidth + $checkboxColumnWidth}) * #{$size}
            );
    }
    $result: $result + " " + $lastColumnWidth;
    @return #{$result};
    // $grid_AccountsList_Header: $grid_checkboxColumnWidth
    //   calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
    //   calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
    //   calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
    //   $grid_lastColumnWidth;
}
@function mixin_get_listingRows(
    $checkboxColumnWidth,
    $lastColumnWidth,
    $sizesInPercentage
) {
    $result: "";
    @for $i from 1 through length($sizesInPercentage) {
        //@each $size in $sizesInPercentage {
        //$i: index($sizesInPercentage, $size);
        $size: nth($sizesInPercentage, $i);

        $extra: "0px";
        @if $i == 1 {
            $extra: #{$checkboxColumnWidth + 5};
        }
        $result: $result +
            " " +
            calc(
                (100% - #{$lastColumnWidth + $checkboxColumnWidth}) *
                    #{$size} +
                    #{$extra}
            );
    }
    $result: $result + " " + $lastColumnWidth;
    @return #{$result};
    // $grid_AccountsList_Row: calc(
    //     ((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3) +
    //         30px
    // )
    // calc((100% - #{$grid_lastColumnWidth + $grid_checkboxColumnWidth}) / 3)
    // 200px;
}
