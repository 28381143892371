$color-background: red;
$color-primary: #00aeef;
$color-tagGreen: #39a909;
$color-tagGrey: #666;
$color-tagOrange: #fdad00;
$color-tagRed: #fa3838;
$color-facebookDark: #4266b2;
$color-facebookLight: #b7c9f0;
$color-dataPresentorTitle: #515151;
$color-dataPresentorFacebookStroke: #517ada;
$color-dataPresentorFacebookArea: #f1f4fc;
$color-dataPresentorFacebookStrokeNegative: #d66253;
$color-dataPresentorTwitter: #1da1f2;
$color-dataPresentorInstagram: #e1306c;

$color-customReport: #efefef;
$color-reportPrevColor: #bbb;

$color-palette-category: #00b7bb;
$color-palette-campaign: #00b0e9;
$color-palette-tag: #9ab0d5;
$color-palette-neutral: #546eb0;
$color-palette-success: #32ac57;
$color-palette-error: #ec315e;
$color-palette-default: #abb5ba;
$color-palette-processing: #00b8f1;
$color-palette-warning: #404041;
$color-palette-info: #00b7bb;
$color-palette-primary: #182f44;

$color_Grey: #cdcac5;
$color_Primary: #00aeef;

$size_ListingRowHeight: 50px;

// To be used with webpack
:export {
    scss_color_background: $color-background;
    scss_color_primary: $color-primary;
    scss_color_tagGreen: $color-tagGreen;
    scss_color_tagOrange: $color-tagOrange;
    scss_color_tagGrey: $color-tagGrey;
    scss_color_tagRed: $color-tagRed;
    scss_color_tagPublishing: $color-tagGrey;
    scss_color_tagFacebookDark: $color-facebookDark;
    scss_color_tagFacebookLight: $color-facebookLight;
    scss_color_dataPresentorTitle: $color-dataPresentorTitle;
    scss_color_dataPresentorFacebookStroke: $color-dataPresentorFacebookStroke;
    scss_color_dataPresentorFacebookStrokeNegative: $color-dataPresentorFacebookStrokeNegative;
    scss_color_dataPresentorFacebookArea: $color-dataPresentorFacebookArea;
    scss_color_dataPresentorTwitter: $color-dataPresentorTwitter;
    scss_color_dataPresentorInstagram: $color-dataPresentorInstagram;
    scss_color_customReport: $color-customReport;
    scss_color_reportPrevColor: $color-reportPrevColor;
    scss_color_pallete_category: $color-palette-category;
    scss_color_pallete_campaign: $color-palette-campaign;
    scss_color_pallete_tag: $color-palette-tag;
    scss_color_pallete_neutral: $color-palette-neutral;
    scss_color_pallete_success: $color-palette-success;
    scss_color_pallete_error: $color-palette-error;
    scss_color_pallete_default: $color-palette-default;
    scss_color_pallete_processing: $color-palette-processing;
    scss_color_pallete_warning: $color-palette-warning;
    scss_color_pallete_info: $color-palette-info;
    scss_color_pallete_primary: $color-palette-primary;
}
